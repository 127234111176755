import React, { useEffect, useState } from 'react'
import { InfoCard } from '../../utils/infoCard'
import Chapel from "../../pictures/Chaple.jpg"
import Reception from "../../pictures/ThePines.png"
import Hotel from "../../pictures/Hotel.png"

export const Locations = () => {

  const [iframeSize, setIframeSize] = useState({width: 600, height: 450});

  useEffect(() => {
    const updateSize = () => {
      const screenWidth = window.innerWidth;
      
      if(screenWidth > 1275) { // For large screens
        setIframeSize({width: 600, height: 450});
      } else { // For small screens
        setIframeSize({width: 220, height: 165});
      }
    };

    // Call updateSize to set initial size based on current screen width
    updateSize();

    // Add event listener for window resize
    window.addEventListener('resize', updateSize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <div className='gridrow'>
      <InfoCard 
        descriptionOnClick={()=>{window.open("https://maps.app.goo.gl/TPyMKvLxhykqCD518", "_blank");}}
        onClick={()=>{window.open("https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=BNTHWHW&corporateCode=0002735364", "_blank");}}
        imagePath={Hotel}
        title ='Hotel'
        description="4302 W Walnut St, Rogers, AR 72756"
      />
      <div style={{display:'flex', justifyContent: 'flex-end', padding: 12}}>
        <h3 style={{maxWidth: '500px'}}>
          We would love for you to stay with us! We have a block of rooms reserved at the Homewood Suites by Hilton Bentonville-Rogers.
          <br/>
        <a href="https://www.hilton.com/en/book/reservation/deeplink/?ctyhocn=BNTHWHW&corporateCode=0002735364" target="_blank" rel="noreferrer">Cooperate code for discount: 0002735364, click here to book a room</a>
        </h3>
      </div>
      <div style={{display:'flex', justifyContent: 'flex-end', padding: 12}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3214.132254265358!2d-94.17788639999999!3d36.3333444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c910eedd65d2af%3A0x9e920ddc043c7c84!2sHomewood%20Suites%20by%20Hilton%20Bentonville-Rogers!5e0!3m2!1sen!2sus!4v1719797614779!5m2!1sen!2sus" width={iframeSize.width} height={iframeSize.height} style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <InfoCard 
        descriptionOnClick={()=>{window.open("https://maps.app.goo.gl/RKmnGh9b16HVMSq76", "_blank");}} 
        onClick={()=>{window.open("https://www.cooperchapel.com/", "_blank");}} 
        imagePath={Chapel} 
        title ='Ceremony' 
        description="504 Memorial Dr, Bella Vista, AR 72714" 
        leftAlign
      />
      <div style={{display:'flex', justifyContent: 'flex-start', padding: 12}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3208.1570664580026!2d-94.24549209999999!3d36.477922299999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c9033afa81c05d%3A0xec3745b88089f9a4!2s504%20Memorial%20Dr%2C%20Bella%20Vista%2C%20AR%2072714!5e0!3m2!1sen!2sus!4v1708294941736!5m2!1sen!2sus" width={iframeSize.width} height={iframeSize.height} style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <InfoCard 
        descriptionOnClick={()=>{window.open("https://maps.app.goo.gl/yYBtTg7JTMqSURPm6", "_blank");}}
        onClick={()=>{window.open("https://www.facebook.com/p/The-Pines-Wedding-Event-Venue-100071788346315/", "_blank");}}
        imagePath={Reception}
        title ='Reception'
        description="20131 AR-72 west, Gravette, AR 72736" 
      />
      <div style={{display:'flex', justifyContent: 'flex-end', padding: 12}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1605.2351785781727!2d-94.47446146011886!3d36.42200770921693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c9a957c24e3c4d%3A0x1083a75920be3a8e!2sThe%20Pines!5e0!3m2!1sen!2sus!4v1718108328176!5m2!1sen!2sus" width={iframeSize.width} height={iframeSize.height} style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
 </div>
  )
}
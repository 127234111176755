import { createContext, useState } from 'react';
import './styles.scss';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Home } from './pages/Home/home';
import { Register } from './pages/register/register';
import { Itinerary } from './pages/Itinerary/itinerary';
import { Bridal } from './pages/Bridal/bridal';
import { config } from './config/config';
import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { DocumentData, DocumentReference, addDoc, collection, doc, getDocs, getFirestore, query, updateDoc } from 'firebase/firestore';
import { Locations } from './pages/Locations/locations';
import LoadingModal from './utils/loadingModal';
import { NavBar } from './utils/NavBar';
import Admin from './pages/Admin/admin';
import Gifts from './pages/Gifts/gifts';
import { Thankyou } from './pages/Thankyou/thankyou';
import DataLoadLayer from './utils/DataLoadLayer';
import FAQ from './pages/FAQ/FAQ';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './utils/AuthContext';
import Login from './pages/Admin/login';

export type Guest = {
  id?: string;
  FirstName: string;
  LastName: string;
  points: number;
  attending?: boolean;
  dietaryPref: string;
};

// Initialize Firebase
export const app = initializeApp(config);
export const analytics = getAnalytics(app);
export const store = getFirestore();

type GuestContextInterface = {
  fetchGuestList: () => Promise<Guest[]>;
  addGuest: (guest: Guest) => Promise<DocumentReference<DocumentData>>;
  updateGuest: (guest: Guest) => Promise<void>;
  guests?: Array<Guest>;
  initGuest: Guest;
};

export const GuestContext = createContext({} as GuestContextInterface);

const App = () => {
  const [guests, setGuests] = useState<Array<Guest>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const addGuest = async (guest: Guest) => {
    console.log('adding guest', guest);
    setLoading(true);
    const guestCollection = collection(store, 'Guests');
    return await addDoc(guestCollection, guest).finally(() => {
      setLoading(false);
    });
  };

  const updateGuest = async (guest: Guest) => {
    console.log('updating guest', guest);
    setLoading(true);
    const guestToUpdate = doc(store, 'Guests', guest.id ?? '');
    return await updateDoc(guestToUpdate, guest).finally(() => {
      setLoading(false);
    });
  };

  const fetchGuestList = async () => {
    setLoading(true);
    const guestCollection = collection(store, 'Guests');
    const guestsQuery = query(guestCollection);
    const querySnapshot = await getDocs(guestsQuery);
    const fetchedData: Array<Guest> = [];
    querySnapshot.forEach((doc) => {
      fetchedData.push({ id: doc.id, ...doc.data() } as Guest);
    });
    setGuests(fetchedData);
    setLoading(false);
    return fetchedData;
  };

  const initGuest: Guest = { FirstName: '', LastName: '', points: 0, dietaryPref: '' };

  return (
    <div>
      <GuestContext.Provider value={{ addGuest, fetchGuestList, updateGuest, guests, initGuest }}>
        <AuthProvider>
          <LoadingModal loading={loading}></LoadingModal>
          <Router>
            <NavBar />
            <Switch>
            <PrivateRoute path="/admin" component={() => (
                <DataLoadLayer>
                  <Admin />
                </DataLoadLayer>
              )} />
              <Route path="/Home">
                <Home />
              </Route>
              <Route path="/Login">
                <Login />
              </Route>
              <Route path="/register" render={() => (
                <DataLoadLayer>
                  <Register />
                </DataLoadLayer>
              )} />
              <Route path="/Locations">
                <Locations></Locations>
              </Route>
              <Route path="/Itinerary">
                <Itinerary></Itinerary>
              </Route>
              <Route path="/BridalParty">
                <Bridal></Bridal>
              </Route>
              <Route path="/Gifts">
                <Gifts></Gifts>
              </Route>
              <Route path="/Thankyou">
                <Thankyou></Thankyou>
              </Route>
              <Route path="/FAQ">
                <FAQ></FAQ>
              </Route>
              <Route path="*">
                <Redirect to="/Home" />
              </Route>
            </Switch>
          </Router>
        </AuthProvider>
      </GuestContext.Provider>
    </div>
  );
};

export default App;

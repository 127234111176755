import React, { useState } from 'react';
import Coffee from "../../pictures/Coffee.png"
import Dinner from "../../pictures/Dinner.png"
import Souvenir from "../../pictures/Souvenir.png"
import Spoil from "../../pictures/Spoil.png"
import Gift from "../../pictures/GiftHands.png"
import './gifts.css'

interface RegistryItem {
  name: string;
  amount: number;
  imageUrl: string;
}

const registryItems: RegistryItem[] = [
  { name: 'Buy us a coffee', amount: 10, imageUrl: Coffee },
  { name: 'Buy us a dinner', amount: 25, imageUrl: Dinner },
  { name: 'Buy us a souvenir', amount: 50, imageUrl: Souvenir },
  { name: 'Spoil us', amount: 100, imageUrl: Spoil },
  { name: 'Give what you want', amount: 0, imageUrl: Gift },
];

const Gifts: React.FC = () => {

  const getCurrencyAmount = (amount: number) => {
    return amount * 100;
  }

  return (
    <div>
      <h2>
        We're blessed with plenty, but if you wish, you can contribute to our dream honeymoon in Japan!
      </h2>
      
      {registryItems.map((item, index) => (
        <div className="registry-item" key={index}>
          <img src={item.imageUrl} alt={item.name} />
          <button onClick={() => window.location.href=`https://donate.stripe.com/6oE5obd2q9ode1q8ww?locale=en&__embed_source=buy_btn_1PW7phFqdDKPJl5LgKInFqvr&__prefilled_amount=${getCurrencyAmount(item.amount)}`}>
            {item.name} - ${item.amount}
          </button>
        </div>
      ))}
    </div>
  );
}

export default Gifts;
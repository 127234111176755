import React from 'react'
import Baeley from "../../pictures/Bitmojis/Baeley.jpg"
import Ben from "../../pictures/Bitmojis/Ben.jpg"
import Cassidy from "../../pictures/Bitmojis/Cassidy.png"
import Chris from "../../pictures/Bitmojis/Chris.png"
import Don from "../../pictures/Bitmojis/Don.png"
import Jake from "../../pictures/Bitmojis/Jake.png"
import Morgan from "../../pictures/Bitmojis/Morgan.jpg"
import Renee from "../../pictures/Bitmojis/Renee.png"
import Ryan from "../../pictures/Bitmojis/Ryan.png"
import Sam from "../../pictures/Bitmojis/Sam.jpg"
import Sheryl from "../../pictures/Bitmojis/Sheryl.png"
import Timmy from "../../pictures/Bitmojis/Timmy.png"
import Tommy from "../../pictures/Bitmojis/Tommy.png"
import Tony from "../../pictures/Bitmojis/Tony.png"
import Will from "../../pictures/Bitmojis/Will.png"
import RyBo from "../../pictures/Bitmojis/RyBo.jpg"

import '../../utils/infoCard.css'
import { InfoCard } from '../../utils/infoCard'

export const Bridal = () => {
  return (
    <div className='gridrow'>
       <InfoCard imagePath={Sam} title ='Bride' description="Samantha O'Neill"></InfoCard>
       <InfoCard imagePath={Tony} title ='Groom' description='Tony Carr' leftAlign></InfoCard>
       <InfoCard imagePath={Cassidy} title ='Maid of Honor' description="Cassidy O'Neill"></InfoCard>
       <InfoCard imagePath={Timmy} title ='Best Man' description='Tim Carr' leftAlign></InfoCard>
       <InfoCard imagePath={Baeley} title ='Bridesmaid' description='Bayley Carder'></InfoCard>
       <InfoCard imagePath={Tommy} title ='Groomsman' description='Tom Carr' leftAlign></InfoCard>
       <InfoCard imagePath={Morgan} title ='Bridesmaid' description='Morgan Rousseau'></InfoCard>
       <InfoCard imagePath={Jake} title ='Groomsman' description='Jake Capoun' leftAlign></InfoCard>
       <InfoCard imagePath={Ben} title ='Bridesman' description='Ben Basham'></InfoCard>
       <InfoCard imagePath={RyBo} title ='Master of Ceremony' description='Ryan Boardman' leftAlign></InfoCard>
       <InfoCard imagePath={Will} title ='Officiant' description='Will Winfrey'></InfoCard>
       <InfoCard imagePath={Sheryl} title ='Mother of Groom' description='Sheryl Mcgreanor' leftAlign></InfoCard>
       <InfoCard imagePath={Renee} title ='Mother of Bride' description="Renee O'Neill"></InfoCard>
       <InfoCard imagePath={Don} title ='Father of Groom' description='Don Mcgreanor' leftAlign></InfoCard>
       <InfoCard imagePath={Chris} title ='Father of Bride' description="Chris O'Neill"></InfoCard>
    </div>
  )
}
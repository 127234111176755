import React, { useState } from 'react'
import './searchBar.css'

interface SearchBarInterface {
  searchTerm: string;
  handleSearchCallback: (input: string) => void;

}

const SearchBar = ({ searchTerm, handleSearchCallback }: SearchBarInterface) => {

  return (
    <div className='searchContainer'>
      <input
        type="text"
        placeholder="Search By Last Name"
        onChange={(e) => { handleSearchCallback(e.target.value) }}
      />
    </div>
  );
};

export default SearchBar;
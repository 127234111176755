import  { useState } from 'react'
import WeddingGoobers from "../../pictures/WeddingGoobers.png"
import './thankyou.css'



export const Thankyou = () => {
  const [samBoop, setSamBoop] = useState(true)
  const [tonyBoop, setTonyBoop] = useState(true)


  return (
    <div>
      <h1>THANK YOU!</h1>
      <div className='pictureCenter'>
        <div className="goobContainer"
        onClick={()=>{}}>
          <img className = {samBoop && tonyBoop ? "rotate" : ""} src={WeddingGoobers} alt="WeddingSvg" ></img>
          <div className="centered-div">
            <div onClick={()=>{setSamBoop(true)}} className="clickable-area-sam"></div>
            <div onClick={()=>{setTonyBoop(true)}} className="clickable-area-tony"></div>
          </div>
        </div>
      </div>
      
    </div>
  )
}

import { title } from 'process'
import React, { ReactNode } from 'react'

export interface infoCardInterface {
    imagePath: string,
    description: string,
    leftAlign?: boolean
    title: string
    onClick?: () => void
    descriptionOnClick?: () => void
}





export const InfoCard = ({ title, imagePath, description, leftAlign, onClick, descriptionOnClick }: infoCardInterface) => {
    const CardTextFormat = () => {
        return (
            <div>
                <div className='title headerText'>
                    {title}
                </div>
                <div onClick={descriptionOnClick} className={'description bodyText ' + (descriptionOnClick === undefined ? "" : " clickable")}>
                    {description}
                </div>
            </div>
        )
    }
    return (
        <div>
            {leftAlign ?
                <div className='grid'>
                    <div className='infoCardHeaderLeft'><img className={(onClick !== undefined ? 'clickable' : '')} onClick={onClick} src={imagePath} /></div>
                    <div className='infoCardDetailsLeft'>
                        <CardTextFormat />
                    </div>
                </div>
                :
                <div className='grid'>
                    <div className='infoCardDetailsRight'>
                        <CardTextFormat />
                    </div>
                    <div className='infoCardHeaderRight'><img className={(onClick !== undefined ? 'clickable' : '')} onClick={onClick} src={imagePath} /></div>
                </div>
            }
        </div>

    )
}
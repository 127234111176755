import React, { ReactComponentElement, useState } from 'react'
import WeddingGoobers from "../../pictures/WeddingGoobers.png"
import SamAnthonySignature from "../../pictures/SamAnthonySignature.svg"
import './home.css'
import { useHistory } from 'react-router-dom';


export const Home = () => {
  const [samBoop, setSamBoop] = useState(false)
  const [tonyBoop, setTonyBoop] = useState(false)
  const [adminClickCount, setAdminClickCount] = useState(0)
  let navigate = useHistory();

  const attemptAdminPage= () => {
    if(samBoop && tonyBoop){
      console.log(adminClickCount)
      setAdminClickCount(adminClickCount + 1)
    }
    if(adminClickCount >= 7){
      navigate.push('/admin');
    }
  }

  return (
    <div>
      <div className='pictureCenter'>
        <div className="goobContainer"
        onClick={()=>{attemptAdminPage()}}>
          <img className = {samBoop && tonyBoop ? "rotate" : ""} src={WeddingGoobers} alt="WeddingSvg" ></img>
          <div className="centered-div">
            <div onClick={()=>{setSamBoop(true)}} className="clickable-area-sam"></div>
            <div onClick={()=>{setTonyBoop(true)}} className="clickable-area-tony"></div>
          </div>
        </div>
      </div>

      <div style={{display:"flex", justifyContent:"center", padding:20}}>
        <iframe frameBorder={0} src="https://itch.io/embed-upload/10812977?color=333333" allowFullScreen={true} width="320" height="340"><a href="https://idomybest.itch.io/danceoffwedding">Play DANCEOFFWEDDING on itch.io</a></iframe>
      </div>
      <span></span>
      <div className='bodyText' style={{color:'black', paddingBottom: '10px'}} >
        <div style={{display:"flex", justifyContent:"center", padding:2}}>How to play:</div>
        <div style={{display:"flex", justifyContent:"center", padding:2}}>Keyboard: </div>
        <div style={{display:"flex", justifyContent:"center", padding:2}}>Use arrow keys to hit the correct direction when the arrows overlap!</div>
        <div style={{display:"flex", justifyContent:"center", padding:2}}>Mobile: </div>
        <div style={{display:"flex", justifyContent:"center", padding:2}}>Swipe the correct direction (up, down, right, left) ​when the arrows overlap!</div>
      </div>
      
    </div>
  )

  
}

import React, { useContext, useRef, useState } from 'react'
import { Guest, GuestContext } from '../App'
import './registerInput.css'

export interface RegisterInputInterface {
    setSubmissionCompleted: (value: boolean) => void,
    currentGuest: Guest,
    setCurrentGuest: React.Dispatch<React.SetStateAction<Guest>>
}


export const RegisterInput = ({ setSubmissionCompleted, setCurrentGuest, currentGuest }: RegisterInputInterface) => {
    const { fetchGuestList, addGuest } = useContext(GuestContext)


    return (
        <div className='containerContainer'>
            <div className="container">
                <div className="text">
                    Guest
                </div>
                <form action="#">
                    <div className="form-row">
                        <div className="input-data">
                            <input
                                onChange={(event) => {
                                    setCurrentGuest(
                                        {
                                            ...currentGuest,
                                            FirstName: event.target.value ?? ''
                                        } as Guest)
                                }}
                                type="text"
                                className='textInput'
                                required
                            >
                            </input>
                            <div className="underline"></div>
                            <label htmlFor="">First Name</label>
                        </div>
                        <div className="input-data">
                            <input
                                onChange={(event) => {
                                    setCurrentGuest(
                                        {
                                            ...currentGuest,
                                            LastName: event.target.value ?? ''
                                        } as Guest)
                                }}
                                type="text"
                                className='textInput'
                                required
                            >
                            </input>
                            <div className="underline"></div>
                            <label htmlFor="">Last Name</label>
                        </div>
                    </div>
                    <div className="form-row submit-btn">
                        <div className="input-data">
                            <div className="inner" ></div>
                            <input
                                className='submitInput'
                                type="submit"
                                value="Add Name"
                                onClick={(e) => {
                                    if (currentGuest.FirstName && currentGuest.LastName) {
                                        e.preventDefault()
                                        addGuest(currentGuest).then((data) => {
                                            console.log(data)
                                            if (data.id) {
                                                fetchGuestList();
                                                setSubmissionCompleted(true)
                                            }
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
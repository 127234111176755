import React from 'react'

import './loadingModal.css';

interface LoadingModalProps {
    loading: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ loading }) => (
    <div className={`loading-modal ${loading ? 'visible' : 'hidden'}`}>
        <div className="loading-bell">🔔</div>
        <p>Loading...</p>
    </div>
);

export default LoadingModal;
import React, { useContext } from 'react'
import "./registerInput.css"
import { Guest, GuestContext } from '../App'


export interface SubmissionCompletedInterface {
    setSubmissionCompleted: (value: boolean) => void,
    currentGuest: Guest,
    setCurrentGuest: React.Dispatch<React.SetStateAction<Guest>>
}

const SubmissionCompleted = ({ setSubmissionCompleted, setCurrentGuest, currentGuest }: SubmissionCompletedInterface) => {

    const { fetchGuestList, addGuest } = useContext(GuestContext)

    return (
        <div className='containerContainer'>
            <div className="container">
                <div className="text">
                    Success
                </div>
                <form>
                    <div className="form-row">
                        {currentGuest.FirstName} {currentGuest.LastName} has been added to the guest registry.
                    </div>
                    <div className="form-row submit-btn">
                        <div className="input-data">
                            <div className="inner"></div>
                            <input
                                className='submitInput'
                                type="submit"
                                value="Add Another Guest"
                                onClick={() => {
                                    setSubmissionCompleted(false)
                                }}
                            />
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default SubmissionCompleted
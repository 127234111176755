import React, { useContext, useState } from 'react'
import { Guest, GuestContext } from '../../App'
import { GuestCard } from '../../utils/guestCard'
import { RegisterInput } from '../../utils/registerInput'
import SubmissionCompleted from '../../utils/submissionCompleted'
import '../../utils/infoCard.css'
import './admin.css'


interface AdminProps {
  guests?: Guest[];
}

const Admin: React.FC<AdminProps> = ({ guests }) => {

  const {initGuest} = useContext(GuestContext)
  const [submissionCompleted, setSubmissionCompleted] = useState(false)
  const [currentGuest, setCurrentGuest] = useState<Guest>(initGuest)

  const setSubmissionCompletedExt = (value : boolean) =>{
    if(!value){
      setCurrentGuest(initGuest)
    }
    setSubmissionCompleted(value)
  }



  const attendingGuests = guests?.filter(guest => guest.attending === true);
  const notAttendingGuests = guests?.filter(guest => guest.attending === false);
  const undecidedGuests = guests?.filter(guest => guest.attending === null || guest.attending === undefined);



  return (
    <div>

    {submissionCompleted ?
        <SubmissionCompleted 
          setSubmissionCompleted={setSubmissionCompletedExt}
          currentGuest={currentGuest}
          setCurrentGuest={setCurrentGuest}
        ></SubmissionCompleted>
      :
        <RegisterInput 
          setSubmissionCompleted={setSubmissionCompletedExt}
          currentGuest={currentGuest}
          setCurrentGuest={setCurrentGuest}
        ></RegisterInput> }

    {/* <div className="guestGrid">
      {guests?.map((guest)=>{return <GuestCard assignedGuest={guest}></GuestCard>})}
    </div> */}

      <div>
          <div className="guesterGrid">
            <div className="guesterColumn">
              <h3>Attending</h3>
              <ul>
                {attendingGuests?.map(guest => <li key={guest.id}>{guest.FirstName} {guest.LastName}</li>)}
              </ul>
            </div>
            <div className="guesterColumn">
              <h3>Not Attending</h3>
              <ul>
                {notAttendingGuests?.map(guest => <li key={guest.id}>{guest.FirstName} {guest.LastName}</li>)}
              </ul>
            </div>
            <div className="guesterColumn">
              <h3>Undecided</h3>
              <ul>
                {undecidedGuests?.map(guest => <li key={guest.id}>{guest.FirstName} {guest.LastName}</li>)}
              </ul>
            </div>
          </div>
        </div>

    </div>
  )
}

export default Admin
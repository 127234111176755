import React, { useContext, useEffect, useState } from 'react'
import { Guest, GuestContext } from '../../App'
import SubmissionCompleted from '../../utils/submissionCompleted'
import { RegisterInput } from '../../utils/registerInput'
// import '../../utils/infoCard.css'
import { GuestCard } from '../../utils/guestCard'
import SearchBar from '../../utils/searchBar'

interface RegisterProps {
  guests?: Guest[];
}

export const Register : React.FC<RegisterProps> = ({guests}) => {
  const [submissionCompleted, setSubmissionCompleted] = useState(false)
  const [currentGuest, setCurrentGuest] = useState<Guest>({FirstName: "", LastName: "", points: 0, attending: false, dietaryPref:''})
  const setSubmissionCompletedExt = (value : boolean) =>{
    if(!value){
      setCurrentGuest({FirstName: "", LastName: "", points: 0, attending: false, dietaryPref:''})
    }
    setSubmissionCompleted(value)
  }
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState<Array<Guest>>([]);

  const sanitizeLastName = (lastName: string) : string =>{
    return lastName.replace(/[’‘`]/g, "'");
  }

  const hasUncommonCharacters = (input :string) => {
    const commonCharRegex = /[^a-zA-Z0-9\s']/;
    return commonCharRegex.test(input);
  };

  const handleSearch = (input : string) => {

    if(!hasUncommonCharacters(input)){
      const filteredDataTemp = guests?.filter((item) =>
        sanitizeLastName(item.LastName.toLowerCase()).includes(input.toLowerCase())
      );
      
      // Sort by LastName alphabetically
      const sortedData = filteredDataTemp?.sort((a, b) => {
        const lastNameA = a.LastName.toLowerCase();
        const lastNameB = b.LastName.toLowerCase();
        if (lastNameA < lastNameB) {
          return -1;
        }
        if (lastNameA > lastNameB) {
          return 1;
        }
        return 0;
      }) ?? [];

      setFilteredData(sortedData);
    }else{
      setFilteredData(guests ?? [])
    }
  };

  const getGuestListDefaulted=()=>{
    if(filteredData?.length > 0){
      return filteredData
    }
    // Sort by LastName alphabetically
    const sortedData = guests?.sort((a, b) => {
      const lastNameA = sanitizeLastName(a.LastName.toLowerCase());
      const lastNameB = sanitizeLastName(b.LastName.toLowerCase());
      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
      return 0;
    }) ?? [];
    return sortedData
  }

  return (
    <div>
      <div className='bodyText' style={{display:'flex', justifyContent: 'center', alignItems:'center', padding: 12}}>
        Click on your name to RSVP!
      </div>
      <SearchBar searchTerm={searchTerm} handleSearchCallback={handleSearch} ></SearchBar>
        <div className="guestGrid">
        {(getGuestListDefaulted())?.map((guest)=>{return <GuestCard assignedGuest={guest} attending={guest.attending}></GuestCard>})}
        </div>
    </div>
  )
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import "./guestCard.css";
import { Guest, GuestContext } from '../App';

const getInitials = (guest: Guest) => {
    const initials = guest?.FirstName[0]?.toUpperCase().concat(guest?.LastName[0]?.toUpperCase() ?? '');
    return initials;
};

export interface GuestCardInterface {
    assignedGuest: Guest,
    attending?: boolean
}

export const GuestCard = ({ assignedGuest, attending }: GuestCardInterface) => {
    const { fetchGuestList, addGuest, updateGuest } = useContext(GuestContext)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [alreadySubmitted, setAlreadySubmitted] = useState(assignedGuest.attending !== undefined)

    const getRSVPStatus = () => {
        if (assignedGuest.attending === undefined) {
            return ''
        } else {
            return assignedGuest.attending ? 'yes' : 'no';
        }
    }
    const [rsvpResponse, setRsvpResponse] = useState(getRSVPStatus());
    const [selectedDiet, setSelectedDiet] = useState(assignedGuest.dietaryPref);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleSubmit = (currentGuest: Guest) => {
        updateGuest({ ...currentGuest, dietaryPref: selectedDiet, attending: rsvpResponse === 'yes' ? true : false }).then((data) => {

        }).finally(() => {
            fetchGuestList();
        })
        closeModal();
    }

    const handleDietChange = (answer: string) => {
        setSelectedDiet(answer);
        if (answer === 'Cannibal') {
            window.open("https://youtu.be/o0u4M6vppCI?si=KcRRu6K_Mh6cMs26&t=52", "_blank");
        }
    }


    const handleRsvp = (answer: string) => {
        assignedGuest.attending = answer === 'yes' ? true : false;
        setRsvpResponse(answer);
    }

    return (
        <div className='guestCardContainerContainer'>
            <div className="guestCardContainer" onClick={openModal}>
                {/* Flag for RSVP status */}
                <div className="rsvp-flag">
                    {getRSVPStatus() !== '' ? "RSVP ✔" : "RSVP ❌"}
                </div>
                <div className="initialCircle">
                    {getInitials(assignedGuest)}
                </div>
                <div className='guestName'>
                    {assignedGuest.FirstName + ' ' + assignedGuest.LastName}
                </div>
            </div>

            {isModalOpen && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <button className="exit-button" onClick={closeModal}>&times;</button>
                        <div>
                            <h1>{assignedGuest.FirstName + ' ' + assignedGuest.LastName}</h1>
                            <h2>Are you going?</h2>
                            <button disabled={alreadySubmitted} className={(rsvpResponse === 'yes' ? 'selected styled-button ' : 'styled-button ')} onClick={(e) => handleRsvp('yes')}>Yes</button>
                            <button disabled={alreadySubmitted}  className={(rsvpResponse === 'no' ? 'selected styled-button ' : 'styled-button ')} onClick={(e) => handleRsvp('no')}>No</button>
                            {rsvpResponse === 'no' && <div className="registration-flag">
                                Sorry you can't make it! If something changes contact Tony to make any edits.
                            </div>}
                            <div className="bottom-controls">
                                <div className="dietary-preferences">
                                    <h3>Dietary Preferences:</h3>
                                    <select
                                        disabled={alreadySubmitted}
                                        className={`styled-select ${selectedDiet !== '' ? 'selected' : ''}`}
                                        value={selectedDiet}
                                        onChange={(e) => handleDietChange(e.target.value)}>
                                        <option value="">None</option>
                                        <option value="Pescatarian">Pescatarian</option>
                                        <option value="Vegetarian">Vegetarian</option>
                                        <option value="Cannibal" >Cannibal?</option>
                                    </select>
                                </div>
                                <div className="submit-button-container">
                                    <button className='styled-button ' onClick={(e) => {
                                        if (!alreadySubmitted) {
                                            e.preventDefault()
                                            setAlreadySubmitted(true)
                                            handleSubmit(assignedGuest)
                                        }
                                    }
                                    }>Submit</button>

                                </div>
                            </div>
                            {alreadySubmitted && (
                                <div className="registration-flag">
                                    You have already registered, If something changes contact Tony to make any edits.
                                </div>)}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
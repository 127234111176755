import React from 'react';
import './timeline.css';
import Ceremony from "../pictures/Chaple.jpg"
import Photos from "../pictures/Photos.png"
import Arrival from "../pictures/Reception.png"

type event = {
    time?: string;
    description: string;
    side?: string;
    imagePath?: string;
};

type day = {
    date: string;
    events?: event[];
};

const events = [
    { date: 'OCT 24 - Thursday', events: [{ description: 'Bride and Groom will be in Arkansas.', imagePath: Arrival }] } as day,
    { date: 'OCT 25 - Friday', events: [{ description: 'Last minute freak out, gather things needed for wedding?' }] } as day,
    { date: 'OCT 26 - Saturday', events: [{ time: '6:30 PM - 7:30PM', description: 'Ceremony Rehearsal' }] } as day,
    {
        date: 'OCT 27 - Sunday',
        events: [
            { time: '4:30PM - 5:30PM', description: 'Ceremony', imagePath: Ceremony } as event,
            { time: '5:30PM - 6:00PM', description: 'Bridal Party and Family Pictures', imagePath: Photos } as event,
            { time: '5:30PM', description: 'Head to the reception Hall' } as event,
            { time: '5:30PM - 6:30PM', description: 'Cocktail hour' } as event,
            { time: '6:30PM', description: 'Wedding Party Entrance' } as event,
            { time: '6:45PM - 7:30PM', description: 'Dinner' } as event,
            { time: '7:30PM', description: 'Toasts' } as event,
            { time: '8:00PM', description: 'First Dance' } as event,
            { time: '8:30PM', description: 'Cake Cutting' } as event,
            { time: '9:00PM', description: 'Bouquet Toss' } as event,
            { time: '10:00PM', description: 'Grand Exit' } as event,
            { time: '10:00PM - TBD', description: 'After Party' } as event
        ]
    } as day,
    { date: 'OCT 28 - Monday', events: [{ description: 'Clean up' }] } as day,
    { date: 'OCT 29 - Tuesday', events: [{ description: 'Go Home' }] } as day,
];

interface TimelineDayInterface {
    date: string;
    events?: event[];
    side: string;
}

const TimelineDay = ({ date, events, side }: TimelineDayInterface) => (
    <div className={`timeline-day ${side}`}>
        <div className={`date-heading ${side}`}>{date}</div>
        <div className={`events-side ${side}`}>
            {events
                ? events.map((event, index) => (
                    <TimelineEvent key={index} {...event} side={side} />
                ))
                : null}
        </div>
    </div>
);

const Timeline = () => (
    <div className="timeline">
        {events.map((event, index) => (
            <TimelineDay key={index} {...event} side={index % 2 === 0 ? "left" : "right"} />
        ))}
    </div>
);
const TimelineEvent = ({ time, description, imagePath, side }: event) => (
    <div className={`timeline-event ${side}`}>
        <div className="event-content">

            <div className="event-info">
                {time && <div className="event-time">{time}</div>}
                <div className="event-description">{description}</div>
            </div>
        </div>
    </div>
);

export default Timeline;
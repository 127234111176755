import React from 'react'
import Timeline from '../../utils/timeline'

export const Itinerary = () => {
  return (
    <div>
      <Timeline></Timeline>
    </div>
    
  )
}
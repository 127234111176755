import { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import SamAnthonySignature from "../pictures/SamAnthonySignature.svg"
import WeddingGoobers from "../pictures/WeddingGoobers.png"
export const NavBar = () => {

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <nav className="navbar-container">
      <NavLink to="/home"><img src={SamAnthonySignature} width={'200px'} height={'auto'} alt="WeddingSvg"></img></NavLink>

      <div className="menu"
        onClick={() => { setMenuOpen(!menuOpen) }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? 'open' : ''} onClick={() => { setMenuOpen(!menuOpen) }} >
        <li className={menuOpen ? 'open' : ''}>
          <NavLink to="/Home" style={{ fontSize: "40px" }}>Home</NavLink>
        </li>
        <li className={menuOpen ? 'open' : ''}>
          <NavLink to="/Register" style={{ fontSize: "40px" }}>RSVP</NavLink>
        </li>
        <li className={menuOpen ? 'open' : ''}>
          <NavLink to="/Locations" style={{ fontSize: "40px" }}>Locations</NavLink>
        </li>
        <li className={menuOpen ? 'open' : ''}>
          <NavLink to="/Itinerary" style={{ fontSize: "40px" }}>Itinerary</NavLink>
        </li>
        <li className={menuOpen ? 'open' : ''}>
          <NavLink to="/FAQ" style={{ fontSize: "40px" }}>FAQ</NavLink>
        </li>
        <li className={menuOpen ? 'open' : ''}>
          <NavLink to="/BridalParty" style={{ fontSize: "40px" }}>Bridal Party</NavLink>
        </li>
        <li className={menuOpen ? 'open' : ''}>
          <NavLink to="/Gifts" style={{ fontSize: "40px" }}>Gifts</NavLink>
        </li>
      </ul>
    </nav>
  );
}
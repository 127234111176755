import React, { useState } from 'react';
import './FAQ.css';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "What is the Dress Code?",
    answer: `We'd love to see our family and friends get dressed up for our big day while also staying comfortable. Find some nice dressy casual attire perfect for enjoying the beautiful fall outdoors! Please no jeans or denim and as always, avoid any shades of white. The Knot has a wonderful article to break down "dressy casual" as well as give some examples of each: <a href="https://www.theknot.com/content/what-to-wear-dressy-casual" target="_blank" rel="noopener noreferrer">The Knot article</a>.`,
  },
  {
    question: "Can I bring a Guest / Date / Plus One?",
    answer: `Due to limited space, we are only able to accommodate those guests formally invited on your wedding invitation. We would also love to share our big day with those we feel are closest with us. If you received a plus one, they will be listed on your invitation as well as have their name on our RSVP page. Thank you for your understanding and please feel free to reach out with any questions.`,
  },
  {
    question: "Are Kids Allowed?",
    answer: `Yes! All invited children should already be listed on the formal invitations as well as on the RSVP page. Please reach out to either Anthony or Samantha if you feel there have been any mistakes.`,
  },
  {
    question: "When do we need to RSVP by?",
    answer: `Please RSVP by September 15th by going to the RSVP page on our website. If you do not RSVP in time, we will assume that you are unable to attend.`,
  },
  {
    question: "What do I do if I can't make it?",
    answer: `You will be dearly missed! If you can not make it to the wedding, please let us know as soon as possible and mark "No" under "Are You Going" after selecting your name on the RSVP page. If you need to change your answer on the RSVP page, please let either Anthony or Samantha know as soon as possible so we can make the appropriate changes. Thank you!`,
  },
  {
    question: "What time should I arrive?",
    answer: `4:00PM. The ceremony will begin at 4:30 PM. Please arrive at least 15-30 minutes prior to the ceremony to allow time for parking, walking to the chapel, and finding a seat. Don't arrive too early as we only have the venue from 4:00PM to  6:00PM, and there may be another wedding before ours, still cleaning up.`,
  },
  {
    question: "Where do I park?",
    answer: `There will be on-site parking at both the ceremony and reception locations. We encourage carpooling or using services such as Uber or Lyft to move from location to location. Please know that there will be alcohol served at the reception and we urge everyone to drink responsibly!`,
  },
  {
    question: "Can I take pictures during the ceremony?",
    answer: `We would love to have an unplugged ceremony, meaning that once the ceremony begins, we ask that all cellphones be put away and silenced. We want everyone to be fully present with us and for our photographer to be able to capture the best pictures which we will share with you. Once the ceremony is over feel free to take as many pictures and videos as you'd like and don't forget to share them with us! You can use the hashtag #samanthonywedding2024 to share!`,
  },
  {
    question: "Will there be an open bar or a cash bar?",
    answer: `At this time, we are planning for a limited open bar with the opportunity for you to bring your own alcohol of choice! The drinks may be on us but the hangover is on you! For this reason, again, we encourage you to Uber, Lyft, or carpool with a designated driver to and from the venues. Please make sure you have a way to get back to your accommodations safely.`,
  },
  {
    question: "Will the wedding be indoors or outdoors?",
    answer: `The ceremony will be held indoors at the beautiful glass Mildred B Cooper Memorial Chapel. Though it will be a light walk to get to the chapel from the parking lot and back. The reception will also be held indoors at the Pines, but we will have access to outdoor activities throughout the evening. As we get closer to our wedding date, please keep an eye on the weather in the area as it could be anywhere from the mid 50s in temperature all the way to the 70s. It would also not be unusual for the area to experience rain at that time.`,
  },
  {
    question: "Are there any restrictions at the Mildred B Cooper Memorial Chapel ceremony venue?",
    answer: `We want to be as respectful as possible to this absolutely stunning venue. Some restrictions that they have asked us to follow include: No glitter, rice, birdseed, confetti, or bubbles. No sparklers or fireworks. No standing on pews or wall/wall ledges. No smoking, vaping, food, or alcohol. No drones are allowed on the property. Thank you for your understanding and please reach out if you have any questions regarding the Mildred B Cooper Memorial Chapel.`,
  },
  {
    question: "Are there any restrictions at The Pines reception venue?",
    answer: `The Pines reception venue does not have as many restrictions as the Cooper Chapel. They have let us no that there is to be no smoking of any kind inside the reception hall. Smoking is only allowed outdoors and guests are to utilize the sand-filled urns to dispose of any cigarettes. Use of rice or any type of confetti or glitter is expressly prohibited as well. Thank you for your understanding and please reach out if you have any questions regarding The Pines wedding venue.`,
  },
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq">
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggle(index)}>
            {item.question}
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: item.answer }} />
        </div>
      ))}
    </div>
  );
};

export default FAQ;
import React, { useContext, useEffect, ReactElement } from 'react';
import { GuestContext } from '../App';
import { Guest } from '../App';

interface DataLoadLayerProps {
  children: ReactElement<{ guests: Guest[] }>;
}

const DataLoadLayer: React.FC<DataLoadLayerProps> = ({ children }) => {
  const { guests, fetchGuestList } = useContext(GuestContext);

  useEffect(() => {
    if (!guests || guests.length === 0) {
      fetchGuestList();
    }
  }, [guests, fetchGuestList]);

  if (!guests || guests.length === 0) {
    return <div>Loading...</div>; // or return a loading spinner
  }

  // Pass guests to children as a prop
  return React.cloneElement(children, { guests });
};

export default DataLoadLayer;